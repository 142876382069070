import Post from "../../types/post.d";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import MasonryLayout from "../../components/feed/MasonryLayout";
import dataService from "../../data/firebase/dataService";
import {
    Autocomplete,
    AutocompleteRenderInputParams,
    Button,
    Stack,
    TextField,
    ToggleButton,
} from "@mui/material";
import "./feed.scss";

const Feed: React.FC = () => {
    const [data, setData] = useState<Post[]>();
    const [tags, setTags] = useState<string[]>([]);
    const [tagFill, setTagFill] = useState<string[]>([]);
    const [newTag, setNewTag] = useState<string>("");
    const [pageNumber] = useState<number>(1);
    const [postPerPage] = useState<number>(3);
    const [up] = useState<boolean>(true);

    return (
        <>
        </>
    );

    // eslint-disable-next-line
    // // useEffect(() => {
    // //     const fetchData = async () => {
    // //         let posts: Post[] = [];
    // //         if (data) {
    // //             if (up) {
    // //                 posts = await dataService.getNextPage(
    // //                     data[data.length - 1],
    // //                     postPerPage
    // //                 );
    // //             } else {
    // //                 posts = await dataService.getPreviousPage(
    // //                     data[0],
    // //                     postPerPage
    // //                 );
    // //             }
    // //         } else {
    // //             posts = await dataService.getAll(postPerPage);
    // //         }
    // //         const freshTags: string[] = await dataService.getTags();

    // //         if (freshTags) {
    // //             setTagFill(freshTags);
    // //         }
    // //         if (posts) {
    // //             setData(posts);
    // //         } else {
    // //             console.log("No posts found: " + posts);
    // //         }
    // //         console.log("Fetch Data: " + data);
    // //     };

    // //     fetchData();
    // // }, [tags]);

    // // const applyFilters = () => {
    // //     const fetchData = async () => {
    // //         const filteredData = await dataService.queryAll(tags);
    // //         setData(filteredData as Post[]);
    // //         console.log(data);
    // //     };
    // //     fetchData();
    // // };

    // // const appendTag = () => {
    // //     if (!newTag) {
    // //         return;
    // //     }
    // //     tags.push(newTag.toUpperCase());
    // //     setNewTag("");
    // //     applyFilters();
    // // };

    // // const removeTag = async (tag: string) => {
    // //     const updatedTags = tags.filter((t) => t !== tag.toUpperCase());
    // //     setTags(updatedTags);
    // //     console.log("Tags: " + updatedTags);
    // // };

    // // const handleInpChange = (
    // //     event: React.ChangeEvent<{}>,
    // //     newValue: string
    // // ) => {
    // //     console.log(newValue);
    // //     setNewTag(newValue as string);
    // //     appendTag();
    // // };

    

    // // return (
    // //     <div className='hero'>
    // //         <div className='top-bar'>
    // //             <div className='filter'>
    // //                 <Stack className='tagInput' direction='row' spacing={2}>
    // //                     <Autocomplete
    // //                         className='tagInput'
    // //                         options={tagFill}
    // //                         getOptionLabel={(option) => option}
    // //                         onChange={(event, newValue) =>
    // //                             handleInpChange(event, newValue as string)
    // //                         }
    // //                         value={newTag}
    // //                         onKeyDown={(e) => {
    // //                             if (e.key === "Enter") {
    // //                                 appendTag();
    // //                             }
    // //                         }}
    // //                         renderInput={(
    // //                             params: AutocompleteRenderInputParams
    // //                         ) => (
    // //                             <TextField
    // //                                 {...params}
    // //                                 variant='outlined'
    // //                                 fullWidth
    // //                                 label='Tags'
    // //                             />
    // //                         )}
    // //                     />

    // //                     {Object.keys(tags).map((tag, index) => {
    // //                         return tags[index] ? (
    // //                             <ToggleButton
    // //                                 key={tags[index]}
    // //                                 aria-label={tags[index]}
    // //                                 value={tags[index]}
    // //                                 className='tagButton'
    // //                                 onClick={() => removeTag(tags[index])}
    // //                                 style={{ borderRadius: "10px" }}
    // //                             >
    // //                                 {tags[index]}
    // //                             </ToggleButton>
    // //                         ) : null;
    // //                     })}
    // //                 </Stack>
    // //             </div>

    // //             <Link to='/feed/upload' className='postBtn'>
    // //                 <Button style={{ color: "black" }} className='postBtn'>
    // //                     Add Post
    // //                 </Button>
    // //             </Link>
    // //         </div>
    // //         {data ? (
    // //             <MasonryLayout posts={data} />
    // //         ) : (
    // //             <p>Oops there aren't any posts</p>
    // //         )}
           
    // //     </div>
    // );
};

export default Feed;
